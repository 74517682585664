/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-04-09 12:07:43
 */
import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import ExtensionIcon from "@mui/icons-material/Extension";
import { ReactComponent as TeamSelector } from "../../icons/untitled-ui/TeamSelector.svg";
import { ReactComponent as TeamDevelopment } from "../../icons/untitled-ui/TeamDevelopment.svg";
import { ReactComponent as TeamDynamics } from "../../icons/untitled-ui/TeamDynamicsNav.svg";
import { ReactComponent as TeamDiagnostics } from "../../icons/untitled-ui/TeamDiagnostics.svg";
import { ReactComponent as TeamDiversity } from "../../icons/untitled-ui/TeamDiversity.svg";
import { ReactComponent as TeamCompatibility } from "../../icons/untitled-ui/TeamCompatibility.svg";
import { ReactComponent as ClickWith } from "../../icons/untitled-ui/ClickWith.svg";
import { paths } from "src/paths";
import { useUser } from "src/hooks/use-user";
import { useTeam } from "src/contexts/team-context";
import CryptoJS from 'crypto-js';
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
export const useSections = () => {
  const user = useUser();
  const { selectedTeamId } = useTeam();
  const [dashboardPerms, setdashboardPerms] = useState([]);
  const teamPermsEn = sessionStorage.getItem("teamPerms");
  const accessTokens = sessionStorage.getItem("accessToken");
  // eslint-disable-next-line
  const decrypted = (() => {
    try {
      if (!teamPermsEn || teamPermsEn === "null") return [];

      const bytes = CryptoJS.AES.decrypt(teamPermsEn, user?.id?.toString());
      const decryptedStr = bytes.toString(CryptoJS.enc.Utf8);

      if (!decryptedStr) return [];

      const parsed = JSON.parse(decryptedStr);
      return Array.isArray(parsed) ? parsed.map(Number) : [];
    } catch (e) {
      console.error("Failed to decrypt teamPermsEn:", e);
      return [];
    }
  })();

  const [teamPerms, setteamPerms] = useState([]);

  const [surveyPerms, setsurveyPerms] = useState([]);
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [])

  const fetchTeamsPerms = async (selectedTeamId) => {

    try {
      if (typeof selectedTeamId === 'number' && selectedTeamId > 0) {
      const response = await mtpDashboardResultsApi.getTeamPermissions(accessTokens, selectedTeamId, user.id);
      setteamPerms(response.data)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchTeamsPerms(selectedTeamId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeamId]);
  async function fetch() {
    if (typeof selectedTeamId === 'number' && selectedTeamId > 0) {
      await mtpDashboardResultsApi.getTeamPermissions(accessTokens, selectedTeamId, user.id);
    }
  }
  useEffect(() => {
    // Retrieve initial data from session storage when component mounts
    const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
    const surveyPermsEn = sessionStorage.getItem("surveyPerms");
    // const teamPermsEn = sessionStorage.getItem("teamPerms");
    // eslint-disable-next-line
    setsurveyPerms(JSON.parse(CryptoJS.AES.decrypt(surveyPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8)).map(Number));
    // eslint-disable-next-line
    setdashboardPerms(JSON.parse(CryptoJS.AES.decrypt(dashboardPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8)).map(Number));
    // eslint-disable-next-line
    // setteamPerms(decrypted);
    // Listen for storage changes to update state
    const handleStorageChange = () => {
      const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
      const surveyPermsEn = sessionStorage.getItem("surveyPerms");
      // const teamPermsEn = sessionStorage.getItem("teamPerms");
      // eslint-disable-next-line
      setsurveyPerms(JSON.parse(CryptoJS.AES.decrypt(surveyPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8)).map(Number));
      // eslint-disable-next-line
      setdashboardPerms(JSON.parse(CryptoJS.AES.decrypt(dashboardPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8)).map(Number));

      // eslint-disable-next-line
      // setteamPerms(decrypted);
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation();
  return useMemo(() => {
    const sections = [
      {
        subheader: "Menu",
        items: [
          {
            title: "Team Selector",
            icon: (
              <SvgIcon fontSize="small">
                <TeamSelector />
              </SvgIcon>
            ),
          },
          teamPerms && dashboardPerms?.includes(14) && (teamPerms?.includes(6) || teamPerms?.includes(7) || teamPerms?.includes(8) || teamPerms?.includes(18)) && {
            title: "Team Dynamics",
            icon: (
              <SvgIcon fontSize="small">
                <TeamDynamics />
              </SvgIcon>
            ),
            items: [
              teamPerms && dashboardPerms?.includes(14) && teamPerms?.includes(6) && {
                title: "Team Diversity",
                path: paths.dashboard.teamDiversity.index,
                icon: (
                  <SvgIcon fontSize="small">
                    <TeamDiversity />
                  </SvgIcon>
                ),
              },
              teamPerms && dashboardPerms?.includes(14) && teamPerms?.includes(7) && {
                title: "Click-With",
                path: paths.dashboard.clickWith.index + "?clickWithType=team",
                icon: (
                  <SvgIcon fontSize="small">
                    <ClickWith />
                  </SvgIcon>
                ),
              },
              user.tenetId !== 0 && teamPerms && dashboardPerms?.includes(14) && teamPerms?.includes(18) && {
                title: "Click-With Organisation",
                path: paths.dashboard.clickWith.index + "?clickWithType=org",
                icon: (
                  <SvgIcon fontSize="small">
                    <ClickWith />
                  </SvgIcon>
                ),
              },
              (teamPerms && dashboardPerms?.includes(14) && teamPerms?.includes(8)) && {
                title: "Team Compatibility",
                path: paths.dashboard.teamCompatability.index,
                icon: (
                  <SvgIcon fontSize="small">
                    <TeamCompatibility />
                  </SvgIcon>
                ),
              }
            ].filter(Boolean),
          },
          surveyPerms && dashboardPerms?.includes(14) && (teamPerms?.includes(5) || surveyPerms?.includes(5)) && {
            title: "Team Diagnostics",
            icon: (
              <SvgIcon fontSize="small">
                <TeamDiagnostics />
              </SvgIcon>
            ),
            items: [
              dashboardPerms && dashboardPerms?.includes(14) && teamPerms?.includes(5) && {
                title: "Team Mojo",
                path: '/teamMojo?navigate=true',
                icon: (
                  <SvgIcon fontSize="small">
                    <ExtensionIcon />
                  </SvgIcon>
                ),
              },
              surveyPerms && surveyPerms?.includes(5) && {
                title: "Team Leadership",
                path: paths.dashboard.leadershipBrand.index,
                icon: (
                  <SvgIcon fontSize="small">
                    <ExtensionIcon />
                  </SvgIcon>
                ),
              },
            ].filter(Boolean),
          },
          teamPerms && dashboardPerms?.includes(14) && teamPerms?.includes(9) && {
            title: "Team Development",
            icon: (
              <SvgIcon fontSize="small">
                <TeamDevelopment />
              </SvgIcon>
            ),
            items: [
              teamPerms && dashboardPerms?.includes(14) && teamPerms?.includes(9) && {
                title: "Team Charter",
                path: paths.dashboard.teamDevelopment.index,
                icon: (
                  <SvgIcon fontSize="small">
                    <TeamDynamics />
                  </SvgIcon>
                ),
              },
            ].filter(Boolean),
          },
        ].filter(Boolean),
      },
    ];

    return sections;
    // eslint-disable-next-line
  }, [dashboardPerms, teamPerms, t]);
};
