/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:49
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-03-21 12:15:33
 */
import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";
import axios from "axios";
import * as msal from "@azure/msal-browser";
import { Url, siteConstant } from "src/config";
import { loginRequest } from "src/libs/auth-config";
import loginApiService from "src/services/loginVerifyAPI";
import CoachesCornerApiServices from 'src/services/coachesCornerApi';
import { useNavigate } from "react-router";
import customerApiService from "src/services/customerApi";
import CryptoJS from 'crypto-js';
import mediaUploadApis from "src/services/mediaUploadApi";
// import CryptoJS from 'crypto-js';
// import { useUser } from 'src/hooks/use-user';
var pc = null;
var issuer = null;
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
  ActionType["SET_ERROR_CASE"] = "SET_ERROR_CASE";
})(ActionType || (ActionType = {}));
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accessToken: null,
  errorCase: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, issuer } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      issuer
    };
  },
  SIGN_IN: (state, action) => {
    const { user, isAuthenticated, errorCase } = action.payload;

    return {
      ...state,
      isAuthenticated: isAuthenticated,
      user,
      errorCase: errorCase,
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SET_ERROR_CASE: (state, action) => {
    return {
      ...state,
      errorCase: action.payload,
    };
  },  
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

/**
 * Authentication Functions
 * signIn Functions, signUp Functions signOut Functions
 * @date 8/2/2023 - 6:35:12 PM
 * @type {*}
 */
export const AuthContext = createContext({
  ...initialState,
  initialize: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  getAccessTocken: () => Promise.resolve(),
  setErrorCase: () => {},
});

export const AuthProvider = (props) => {
  const { children } = props;
  issuer = localStorage.getItem(siteConstant.AUTH_ISSUER_STORAGE_KEY);
  const [publicClient, setPublicClient] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const searchParams = new URLSearchParams(window.location.search);
  const islLoginParam = searchParams.get('isllogin');
  const userLoginParam = searchParams.get('userlogin');
  const tokenParameter = searchParams.get('token');
  const islAdminLoginParameter = searchParams.get('isladminlogin');
  const navigate = useNavigate();
  // const user = useUser();
  // eslint-disable-next-line
  // const accessToken = sessionStorage.getItem("accessToken");

  // const fetchBackgroundData = async () => {
  //   try {
  //     const accessTokens = sessionStorage.getItem("accessToken");
  //     await loginApiService.getDashboardIds(accessTokens, user.id);
  //     await loginApiService.getSurveyIds(accessTokens, user.id);
  //   } catch (error) {
  //   }
  // };
  // useEffect(() => {
  // eslint-disable-next-line
  // if (user.id !== undefined && user.type != "Coach") {
  // fetchBackgroundData();
  // }
  // const interval = setInterval(fetchBackgroundData, 180000);
  // return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const navigateToMTPResult =async ()=>{
  //   await initialize();
  //   navigate('/MTP')
  // }

  // const accessToken = sessionStorage.getItem(siteConstant.AUTH_TOKEN_STORAGE_KEY);
  // useEffect(()=>{
  //   if(isMTPResult && accessToken){
  //     navigateToMTPResult()
  //   }
  //     },[isMTPResult,accessToken])
  const setErrorCase = (value) => {
    dispatch({ type: ActionType.SET_ERROR_CASE, payload: value });
  };  
  const initialize = useCallback(async () => {
    issuer = localStorage.getItem(siteConstant.AUTH_ISSUER_STORAGE_KEY);
    var user = {};
    try {
      if (issuer === 'jwt') {
        const accessToken = sessionStorage.getItem(siteConstant.AUTH_TOKEN_STORAGE_KEY);
        const data = { accessToken: accessToken };
        if (accessToken) {
          try {
            const response = await axios.post(
              `${Url}/auth/decodeToken`,
              data
            );
            user = response.data;
            sessionStorage.setItem(siteConstant.AUTH_PROFILE_STORAGE_KEY, JSON.stringify(user));
            dispatch({
              type: ActionType.INITIALIZE,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          } catch (error) {
            console.log(error.message);
          }
        }
        else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
      else if (issuer === 'azure') {
        let msConfig = JSON.parse(localStorage.getItem(siteConstant.AZURE_CONFIG_STORAGE_KEY) ? localStorage.getItem(siteConstant.AZURE_CONFIG_STORAGE_KEY) : {});
        pc = new msal.PublicClientApplication(msConfig);
        setPublicClient(oldPc => (pc));
        //pc.clearCache()
        pc.initialize().then(async () => {
          const accounts = pc.getAllAccounts();
          if (accounts.length > 0) {
            pc.setActiveAccount(accounts[0]);
            user = await getProfielData(accounts[0]);
            dispatch({
              type: ActionType.INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: user
              },
            });
          }
          else {
            setPublicClient(oldPc => (pc));
            dispatch({
              type: ActionType.INITIALIZE,
              payload: {
                isAuthenticated: false,
                user: null
              },
            });
          }

          pc.addEventCallback(async (event) => {
            if (event.eventType === msal.EventType.LOGIN_SUCCESS && event.payload) {
              const payload = event.payload;
              const account = payload.account;
              dispatch({
                type: ActionType.SIGN_IN,
                payload: {
                  isAuthenticated: true,
                  user: account
                },
              });
              user = await getProfielData(account);
              pc.setActiveAccount(account);
              dispatch({
                type: ActionType.SIGN_IN,
                payload: {
                  isAuthenticated: true,
                  user,
                  issuer: issuer
                },
              });
            }
          });
        })
        // eslint-disable-next-line
      }
      else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch, setPublicClient]);

  useEffect(() => {
    const init = async () => {
      await initialize();
    }
    init();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (data, method, msConfig) => {
      var user = {};
      issuer = localStorage.getItem(siteConstant.AUTH_ISSUER_STORAGE_KEY);
      try {
        if (issuer === 'jwt') {
          dispatch({
            type: ActionType.SIGN_IN,
            payload: {
              isAuthenticated: false,
              user: null,
              errorCase: null,
            },
          });
          const response = await axios.post(`${Url}/auth/login`, data);
          console.log("--response--", response);
          const userProfile = response.data;
          console.log("==userProfile==", userProfile);
          // Check if changePassword is required and store the key in localStorage
          if (userProfile.changePassword === 1) {
            if (userProfile.key) {
              // Store the key in localStorage
              localStorage.setItem("resetKey", userProfile.key);
            }
          };
          const token = response.headers["x-access-token"];
          const tokenData = { accessToken: token };
          const responseUser = await axios.post(
            `${Url}/auth/decodeToken`,
            tokenData
          );
          sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, token);
          user = responseUser.data;
          const joyridestatus = await customerApiService.getJoyride(token, user.userId);
          const dashboardPerms = await loginApiService.getDashboardIds(token, user.userId);
          const surveyPerms = await loginApiService.getSurveyIds(token, user.userId);
          // eslint-disable-next-line
          const profilePicture = await mediaUploadApis.getProfilePicture(token, user.userId);
          const encryptedJoyRide = CryptoJS.AES.encrypt(JSON.stringify(joyridestatus.data), user.userId.toString()).toString();
          const encryptedDashboardPerms = CryptoJS.AES.encrypt(JSON.stringify(dashboardPerms.dashIds), user.userId.toString()).toString();
          const encryptedSurveyPerms = CryptoJS.AES.encrypt(JSON.stringify(surveyPerms.surveyIds), user.userId.toString()).toString();
          sessionStorage.setItem(siteConstant.AUTH_PROFILE_STORAGE_KEY, JSON.stringify(user));
          if (data.isChecked) {
            localStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, token);
            localStorage.setItem(siteConstant.AUTH_PROFILE_STORAGE_KEY, JSON.stringify(user));
            localStorage.setItem("joyrideStatus", encryptedJoyRide);
            localStorage.setItem("dashboardPerms", encryptedDashboardPerms);
            localStorage.setItem("surveyPerms", encryptedSurveyPerms);
          };
          dispatch({
            type: ActionType.SIGN_IN,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        }
        else if (issuer === 'azure') {
          const signInType = (isIE || isEdge) ? "loginRedirect" : method;
          if (signInType === "loginPopup") {
            try {
              if (!publicClient) {
                setTimeout(async () => {
                  await pc.loginPopup(loginRequest);
                }, 3000);
              }
              else {
                await publicClient.loginPopup(loginRequest);
              }

            } catch (error) {
              console.log(error);
              dispatch({
                type: ActionType.SIGN_IN,
                payload: {
                  isAuthenticated: false,
                  user: null,
                  errorCase: error.message,
                },
              });
            }
          } else if (signInType === "loginRedirect") {
            publicClient.loginRedirect(loginRequest)
          }
        }
      } catch (error) {
        const errorMessage = error.response.data.message;

        // if (errorMessage === "Invalid password" || errorMessage === "Too many incorrect password attempts. Your account is now locked. Please reach out to an administrator to reset your password.") {
        //   if (errorMessage === "Invalid password") {
        //     // Increment the invalidCredentialCount
        //     let invalidCredentialCount = sessionStorage.getItem('invalidCredentialCount');

        //     if (invalidCredentialCount === null) {
        //       invalidCredentialCount = 0;
        //     } else {
        //       invalidCredentialCount = parseInt(invalidCredentialCount, 10);
        //     }

        //     invalidCredentialCount += 1;
        //     sessionStorage.setItem('invalidCredentialCount', invalidCredentialCount);
        //   } else {
        //     // Reset the invalidCredentialCount to 0
        //     sessionStorage.setItem('invalidCredentialCount', 0);
        //   }
        // }

        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            isAuthenticated: false,
            user: null,
            errorCase: errorMessage,
          },
        });
      }
    },
    [dispatch, publicClient]
  );

  const signUp = useCallback(
    async (email, name, password) => {
      const { accessToken } = await authApi.signUp({ email, name, password });
      const user = await authApi.me({ accessToken });
      sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, accessToken);
      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    await loginApiService.userLogout();
    sessionStorage.removeItem(siteConstant.AUTH_TOKEN_STORAGE_KEY);
    sessionStorage.removeItem(siteConstant.AUTH_PROFILE_STORAGE_KEY);
    sessionStorage.removeItem("dashboardPerms");
    sessionStorage.removeItem("surveyPerms");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("teamDashboardTeamId");
    sessionStorage.removeItem("teamDashboardTeamName");
    Object.keys(sessionStorage).forEach(key => {
      if (key.startsWith("mtpStatus_")) sessionStorage.removeItem(key);
    });
    sessionStorage.removeItem("teamPerms");
    sessionStorage.removeItem("invalidCredentialCount");
    sessionStorage.removeItem("joyrideStatus");
    sessionStorage.removeItem("profilePicture");
    sessionStorage.removeItem("strapiKey");
    localStorage.removeItem(siteConstant.AUTH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(siteConstant.AUTH_PROFILE_STORAGE_KEY);
    localStorage.removeItem("dashboardPerms")
    localStorage.removeItem("surveyPerms")
    localStorage.removeItem("joyrideStatus")
    localStorage.removeItem("userDetails")
    localStorage.removeItem("remberMe")
    publicClient?.clearCache()
    dispatch({
      type: ActionType.SIGN_OUT,
      payload: {
        isAuthenticated: false,
        isInitialized: false,
        user: null,
      },
    });
  }, [dispatch, publicClient]);

  const getAccessTocken = useCallback(async () => {
    const account = publicClient.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //pc.setActiveAccount(accounts[0]);
    let user = await getProfielData(account);
    /* const response = await publicClient.acquireTokenSilent({
        ...loginRequest,
        account: account
    }); */

    dispatch({
      type: ActionType.SIGN_IN,
      payload: {
        isAuthenticated: true,
        isInitialized: true,
        user: user,
      },
    });
  }, [dispatch, publicClient]);
  /**
   * Decode token and get Profile data
   * @param {*} account  // authenticated account details
   */
  const getProfielData = async (account) => {
    let user = {};
    try {
      const data = { userEmail: account.username };
      const response = await axios.post(`${Url}/auth/getProfile`, data);
      const token = response.headers["x-access-token"];
      const tokenData = { accessToken: token };
      const responseUser = await axios.post(
        `${Url}/auth/decodeToken`,
        tokenData
      );
      sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, token);
      user = responseUser.data;
      await customerApiService.getJoyride(token, user.userId);
      await loginApiService.getDashboardIds(token, user.userId);
      await loginApiService.getSurveyIds(token, user.userId);
      await mediaUploadApis.getProfilePicture(token, user.userId);
      sessionStorage.setItem(siteConstant.AUTH_PROFILE_STORAGE_KEY, JSON.stringify(user));
    } catch (error) {
      console.log('error ', error);
    }
    return user;
  }
  useEffect(() => {
    if (islLoginParam !== null) {
      islLogin();
      // userLogin();
    }
    // eslint-disable-next-line
  }, [islLoginParam])
  useEffect(() => {
    if (userLoginParam !== null) {
      // islLogin();
      userLogin();
    }
    // eslint-disable-next-line
  }, [userLoginParam])
  useEffect(() => {
    if (islAdminLoginParameter !== null) {
      // islLogin();
      islAdminLogin();
    }
    // eslint-disable-next-line
  }, [islAdminLoginParameter])
  
  /**
 * Handles isl user login by decoding credentials, fetching authentication details, 
 * storing necessary tokens, and navigating the user to the appropriate dashboard.
 * 
 * @async
 * @function userLogin
 * @returns {Promise<void>} Logs in the user and updates session storage.
 */
  const islLogin = async () => {
    try {
      let cred = atob(islLoginParam);
      let dummyTokenParameter = atob(tokenParameter);
      const [beforeISLLOGIN, afterISLLOGIN] = cred.split('ISLLOGIN');
      const body = {
        "tenantId": beforeISLLOGIN,
        "userid": afterISLLOGIN
      }
      const details = await CoachesCornerApiServices.gettoken(dummyTokenParameter,
        body
      );
      if (details) {
        localStorage.setItem('issuer', 'jwt');
        const stringifiedUser = JSON.stringify(details.data)
        // const stringifiedperms = JSON.stringify(details.dashboardPerms)
        sessionStorage.setItem("userDetails", stringifiedUser);
        sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, details.token);
        await customerApiService.getJoyride(details.token, afterISLLOGIN);
        await loginApiService.getDashboardIds(details.token, afterISLLOGIN);
        await loginApiService.getSurveyIds(details.token, afterISLLOGIN);
        await mediaUploadApis.getProfilePicture(details.token, afterISLLOGIN);
        await initialize();
        // switch (details.data.roleId) {
        //   case 1000:
        // setTimeout(() => {
        navigate('/dashboard', { replace: true });
        // }, 3000);
        //     break;
        //   case 7:
        //     navigate(`/mtp`);
        //     break;
        //   case 15:
        //     navigate(`/leadSelf`);
        //     break;
        //   default:
        //     // Handle the case where dashid is not within the range of 1 to 10
        //     break;
        // }
      }

    } catch (error) {
      console.error("Error fetching sector data:", error);
    }
  };
  /**
 * Handles isl Admin login by decoding credentials, fetching authentication details, 
 * storing necessary tokens, and navigating the user to the appropriate dashboard.
 * 
 * @async
 * @function userLogin
 * @returns {Promise<void>} Logs in the user and updates session storage.
 */
  const islAdminLogin = async () => {
    try {
      let cred = atob(islAdminLoginParameter);
      let dummyTokenParameter = atob(tokenParameter);
      const [beforeISLLOGIN, afterISLLOGIN] = cred.split('ISLADMINLOGIN');
      const body = {
        "tenantId": beforeISLLOGIN,
        "userid": afterISLLOGIN
      }
      const details = await CoachesCornerApiServices.getAdmintoken(dummyTokenParameter,
        body
      );
      if (details) {
        localStorage.setItem('issuer', 'jwt');
        const stringifiedUser = JSON.stringify(details.data)
        // const stringifiedperms = JSON.stringify(details.dashboardPerms)
        sessionStorage.setItem("userDetails", stringifiedUser);
        sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, details.token);
        await customerApiService.getJoyride(details.token, afterISLLOGIN);
        await loginApiService.getDashboardIds(details.token, afterISLLOGIN);
        await loginApiService.getSurveyIds(details.token, afterISLLOGIN);
        await mediaUploadApis.getProfilePicture(details.token, afterISLLOGIN);
        await initialize();
        // switch (details.data.roleId) {
        //   case 1000:
        // setTimeout(() => {
        navigate('/dashboard', { replace: true });
        // }, 3000);
        //     break;
        //   case 7:
        //     navigate(`/mtp`);
        //     break;
        //   case 15:
        //     navigate(`/leadSelf`);
        //     break;
        //   default:
        //     // Handle the case where dashid is not within the range of 1 to 10
        //     break;
        // }
      }

    } catch (error) {
      console.error("Error fetching sector data:", error);
    }
  };

  /**
 * Handles user login by decoding credentials, fetching authentication details, 
 * storing necessary tokens, and navigating the user to the appropriate dashboard.
 * 
 * @async
 * @function userLogin
 * @returns {Promise<void>} Logs in the user and updates session storage.
 */
  const userLogin = async () => {
    try {
      let cred = atob(userLoginParam);
      let dummyTokenParameter = atob(tokenParameter);
      const [beforeISLLOGIN, afterISLLOGIN] = cred.split('USERLOGIN');
      const body = {
        "tenantId": beforeISLLOGIN,
        "userid": afterISLLOGIN
      }
      const details = await CoachesCornerApiServices.gettoken(dummyTokenParameter,
        body
      );
      if (details) {
        localStorage.setItem('issuer', 'jwt');
        const stringifiedUser = JSON.stringify(details.data)
        // const stringifiedperms = JSON.stringify(details.dashboardPerms)
        sessionStorage.setItem("userDetails", stringifiedUser);
        sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, details.token);
        await customerApiService.getJoyride(details.token, afterISLLOGIN);
        await loginApiService.getDashboardIds(details.token, afterISLLOGIN);
        await loginApiService.getSurveyIds(details.token, afterISLLOGIN);
        await mediaUploadApis.getProfilePicture(details.token, afterISLLOGIN);
        await initialize();
        // switch (details.data.roleId) {
        //   case 1000:
        // setTimeout(() => {
        navigate('/dashboard', { replace: true });
        // }, 3000);
        //     break;
        //   case 7:
        //     navigate(`/mtp`);
        //     break;
        //   case 15:
        //     navigate(`/leadSelf`);
        //     break;
        //   default:
        //     // Handle the case where dashid is not within the range of 1 to 10
        //     break;
        // }
      }

    } catch (error) {
      console.error("Error fetching sector data:", error);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: issuer,
        signIn,
        signUp,
        signOut,
        initialize,
        getAccessTocken,
        setErrorCase
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export const AuthConsumer = AuthContext.Consumer;
