/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-04-04 15:45:23
 */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import { tokens } from "src/locales/tokens";
// import { paths } from "src/paths";
import { ReactComponent as TeamPulse } from "../../icons/untitled-ui/teamPulse.svg";
import { useUser } from "src/hooks/use-user";
import CryptoJS from 'crypto-js';
import teamDashboardResultsApi from "src/services/teamdashboardApi";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
export const useSections = () => {
  const { t } = useTranslation();
  const user = useUser();
  const [teamPath, setTeamPath] = useState('/teamDiversity');
  const [leadSelfPath, setLeadSelfPath] = useState('/leadSelf');
  const [selectedTeamId, setSelectetedTeamId] = useState();
  const accessToken = sessionStorage.getItem("accessToken");
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const dashboardPerms = CryptoJS.AES.decrypt(dashboardPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);

  const fetchUsersTeams = async () => {
    try {
      const response = await teamDashboardResultsApi.getusersTeamId(
        accessToken,
        user.id
      );
      setSelectetedTeamId(response[0])
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchUsersTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getPermissions = async () => {
      await fetchPermissions();
      if (dashboardPerms && JSON.parse(dashboardPerms).includes(14)) {
        await fetchTeamsPermissions(selectedTeamId);
      }
    };
    getPermissions();
    // eslint-disable-next-line
  }, [selectedTeamId, dashboardPerms]);

  const fetchTeamsPermissions = async (selectedTeamId) => {
    try {
      let teamId = sessionStorage.getItem("teamSelectedTeam");
      let teamResponse = [];
      // if (typeof selectedTeamId === 'number' && selectedTeamId > 0) {
      teamResponse = await mtpDashboardResultsApi.getTeamPermissions(
        accessToken,
        (teamId !== "undefined" && teamId?.length > 0) ? Number(teamId) : selectedTeamId,
        user.id
      );
      // }

      const permissions = teamResponse.data;

      if (permissions.includes(6)) {
        setTeamPath('/teamDiversity?teamId=${}');
      } else if (permissions.includes(7)) {
        setTeamPath('/clickWith?clickWithType=team');
      } else if (permissions.includes(18) && user.tenetId !== 0) {
        setTeamPath('/clickWith?clickWithType=org');
      } else if (permissions.includes(8)) {
        setTeamPath('/teamCompatability');
      } else if (permissions.includes(5)) {
        setTeamPath('/teamMojo');
      } else if (surveyPerms && surveyPerms.includes(5)) {
        setTeamPath('/leadershipBrand');
      } else if (permissions.includes(9)) {
        setTeamPath('/teamDevelopment');
      } else {
        setTeamPath('/teamDiversity');
      }

    } catch (error) {
      console.log(error.message);
    }

  };

  const fetchPermissions = async () => {
    try {

      const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
      const surveyPermsEn = sessionStorage.getItem("surveyPerms");
      const surveyPerms = surveyPermsEn && CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);
      const dashboardPerms = dashboardPermsEn && CryptoJS.AES.decrypt(dashboardPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);

      // eslint-disable-next-line
      if (surveyPerms && (JSON.parse(surveyPerms).includes(7) || surveyPerms && JSON.parse(surveyPerms).includes(8))) {
        // window.location.reload();
        setLeadSelfPath('/leadSelf');
        // eslint-disable-next-line
      } else if (surveyPerms && (JSON.parse(surveyPerms).includes(9) || surveyPerms && JSON.parse(surveyPerms).includes(12))) {
        setLeadSelfPath('/myMojo');
        // eslint-disable-next-line
      } else if (surveyPerms && JSON.parse(surveyPerms).includes(10)) {
        setLeadSelfPath('/myLeadership');
        // eslint-disable-next-line
      } else if (surveyPerms && JSON.parse(surveyPerms).includes(11)) {
        setLeadSelfPath('/myExecutiveHealth');
        // eslint-disable-next-line
      } else if (surveyPerms && JSON.parse(surveyPerms).includes(6) && JSON.parse(dashboardPerms).includes(13)) {
        setLeadSelfPath('/myPulseTrends');
        // eslint-disable-next-line
      } else if (Number(user.tenetId) === 0 && JSON.parse(dashboardPerms)?.includes(8)) {
        setLeadSelfPath('/goalsAndPulses');
        // eslint-disable-next-line
      } else if (Number(user.tenetId) === 0 && JSON.parse(dashboardPerms).includes(9)) {
        setLeadSelfPath('/conversationWithBoss');
        // eslint-disable-next-line
      } else if (Number(user.tenetId) === 0 && JSON.parse(dashboardPerms)?.includes(12)) {
        setLeadSelfPath(window.location.href = '/coachescorner');
      } else {
        setLeadSelfPath('/leadSelf');
      }

    } catch (error) {
      // setIsLoadingPermission(false);
      console.log(error.message);
    }

  };
  console.log("teamPath", teamPath);

  return useMemo(() => {
    const sections = [
      {
        subheader: t(tokens.nav.menu),
        items: [
          {
            title: t(tokens.nav.home),
            path: leadSelfPath,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },

        ],
      },
    ];
    if (dashboardPerms && JSON.parse(dashboardPerms).includes(14)) {
      sections[0].items.push({
        title: "Team",
        path: `${teamPath}`,
        icon: (
          <SvgIcon fontSize="small">
            <BarChartSquare02Icon />
          </SvgIcon>
        ),
      });
    }
    // Check if dashboardPerms includes the required value (7)
    if (surveyPerms && JSON.parse(surveyPerms).includes(6)) {
      sections[0].items.push({
        title: t(tokens.nav.myMojo),
        path: '/MTP?navigated=true',
        icon: (
          <SvgIcon fontSize="small">
            <TeamPulse />
          </SvgIcon>
        ),
      });
    }

    return sections;
  }, [t, dashboardPerms, surveyPerms, teamPath, leadSelfPath]);
};
