/**
 * @module TeamDashboardTeamSelector
 */
/**
 * @Author: Anandp
 * @Date:   2024-12-10 15:13:21
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-04-08 15:44:13
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { createTypography } from "src/theme/base/customTypography";
import { Scrollbar } from "src/components/scrollbar";
// import { toast } from "react-hot-toast";
import { toast } from 'react-toastify';

// import { ReactComponent as PlusBUtton } from "src/icons/untitled-ui/+ButtonWhite.svg";
import {
  Checkbox,
  Typography,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  // AccordionDetails,
} from "@mui/material";
import { useUser } from "src/hooks/use-user";
import { useEffect } from "react";
import teamDashboardResultsApi from "src/services/teamdashboardApi";
import { useState } from "react";
import { useTeam } from "src/contexts/team-context";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { OutlinedInput, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import SvgIcon from "@mui/material/SvgIcon";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook
const SIDE_NAV_WIDTH = 210;
const SIDE_NAV_MINIMIZED_WIDTH = 50; 

export default function TemporaryDrawer(props) {
  const setDrawerOpen = props.setDrawerOpen;
  const { isMinimized } = useSidebar();
  const [searchData, setSearchData] = useState("");
  const typography = createTypography();
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(true);
  const [noTeam, setNoTeam] = useState(false);
  const { selectedTeamId, setTeamId, setTeamName, clickWithPage, setToClickWithOrg, clickWithOrg, seteamChange,setIsLoadingTeam } = useTeam();
  const [selectedDrawerTeamId, setSelectedTeamId] = useState(null);
  const [teamsList, setTeamsList] = useState();

  /**
   * Fetches the user's teams from the API and updates the teams list or handles errors.
   *
   * @function fetchUsersTeams
   * @returns {void}
   *
   * @description
   * This function sends a request to fetch the user's teams using the provided `accessToken` and `user.id`. 
   * It handles various scenarios:
   * - If the API returns a message, it displays an error toast with that message.
   * - If no teams are found for the user, it sets the `noTeam` state to `true`.
   * - If teams are found, it updates the `teamsList` state with the user's teams.
   * The function also manages the loading state (`isLoading`) appropriately.
   *
   * @example
   * // Fetch the user's teams and update the relevant states
   * fetchUsersTeams();
   */
  const fetchUsersTeams = async () => {
    try {
      const response = await teamDashboardResultsApi.getUsersTeams(
        accessToken,
        user.id
      );
      if(response.message){
        toast.error(response.message)
        setIsLoading(false);
      }else if (response.teamDetails.userDetails.length === 0){
        setNoTeam(true);
        setIsLoading(false);
      }else{
        setTeamsList(response.teamDetails.userDetails);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchUsersTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const storedTeamId = sessionStorage.getItem("teamDashboardTeamId");
    const storedTeamName = sessionStorage.getItem("teamDashboardTeamName");
    if(teamsList){
      if (storedTeamId && storedTeamName) {
        const teamIdAsNumber = Number(storedTeamId);
        setTeamId(teamIdAsNumber);
        setTeamName(storedTeamName);
      } else {
        // If sessionStorage doesn't have teamDashboardTeamId, set the teamId from the API response
        setTeamId(teamsList[0].teamId);
        setTeamName(teamsList[0].teamName);
      }
    }
    
    // if (teamsList && teamsList.length > 0) {
    //   setTeamName(teamsList[0].teamName);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsList]);

  useEffect(() => {
    if (selectedDrawerTeamId) {
      setTeamId(selectedDrawerTeamId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrawerTeamId]);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    // Check if the click event originated from within a checkbox
    if (event.target.tagName === "INPUT" && event.target.type === "checkbox") {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  /**
   * Handles the change event for selecting a team and updates the state accordingly.
   *
   * @function handleChange1
   * @param {Object} event - The event object triggered by the change action.
   * @param {number} teamId - The ID of the selected team.
   * @param {string} teamName - The name of the selected team.
   * @returns {void}
   *
   * @description
   * This function is responsible for handling the change event when a team is selected.
   * It updates the following states:
   * - `selectedTeamId` with the provided `teamId`.
   * - `teamName` with the provided `teamName`.
   * - It also updates sessionStorage with the selected team’s ID and name for persistence across sessions.
   * - Additionally, the function resets certain state values like `toClickWithOrg` and `state`.
   *
   * @example
   * // Handle team selection change
   * handleChange1(event, 1, "Team A");
   */
  const handleChange1 = (event, teamId, teamName) => {
    setIsLoadingTeam(true);
    setToClickWithOrg(false);
    setSelectedTeamId(teamId); // Assuming you have a state named selectedTeamId
    setState("left", false);
    setTeamName(teamName);
    // Set teamId into sessionStorage
    sessionStorage.setItem("teamDashboardTeamId", teamId);
    sessionStorage.setItem("teamDashboardTeamName", teamName);
    seteamChange(true);
  };

  // const handleClickWithOrganisation = (event) => {
  //   const isChecked = event.target.checked;
  //   if (isChecked) {
  //     setToClickWithOrg(true); // If isChecked is true, set it to true
  //   } else {
  //     setToClickWithOrg(false); // If isChecked is false, set it to false
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // callFunction(); // Call your function here
    }
  };


  const filteredTeamsList = searchData
  ? teamsList?.filter(team =>
      team.teamName.toLowerCase().includes(searchData.toLowerCase())
    )
  : teamsList;  // If no searchData, show the entire teamsList

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : {lg:'310px', md:'310px', sm:'280px', xs:'280px'},
        height: "100%",
        backgroundColor: "#583F99",
        borderRadius: "0 15px 15px 0",
        zIndex: 1201,
        paddingLeft: {lg:'45px',md:'45px',sm:'5px',xs:'5px'},
        paddingTop: "25px",
       
      }}
      role="presentation"
      onClick={(event) => {
        if (event.target !== event.currentTarget) return; // Don't close on input events
        toggleDrawer(anchor, false);
      }}
      onKeyDown={(event) => {
        if (event.target !== event.currentTarget) return; // Don't close on input events
        toggleDrawer(anchor, false);
      }}
      p={2}
    >
      <Typography
        sx={{ ...typography.subtitle.regularColorless, color: "white" }}
      >
        Team Selector
      </Typography>
      <OutlinedInput
          value={searchData}
          autoFocus
          fullWidth
          onKeyDown={handleKeyDown}
          sx={{
            '& .MuiInputBase-input': { padding: '8px 0!important' },
            '&.MuiInputBase-root': { backgroundColor: '#fff!important' },
            '& .MuiInputAdornment-root path': { fill: '#535B66' },
            '& .MuiSvgIcon-root': { width: '16px', height: '16px' },
            ' input::placeholder': { color: '#535B66', fontSize: '12px' },
            

          }}
          placeholder="Search"
          onChange={(e) => setSearchData(e.target.value)}
          startAdornment={
            <InputAdornment position="start"
            >
              <SvgIcon>
                <SearchIcon fontSize="small" />
              </SvgIcon>
            </InputAdornment>
          }
        />
      <Scrollbar style={{ maxHeight: "100%", width: "100%" }}>
      {!clickWithOrg && !selectedTeamId && noTeam && (
          <List>
            <div>
              <Accordion sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary>
                  <Typography
                    sx={{
                      ...typography.subtitle.regularColorless,
                      color: "white",
                    }}
                  >
                    No team to list down
                  </Typography>
                </AccordionSummary>
              </Accordion>
            </div>
          </List>
        )}
        {clickWithOrg && clickWithPage && (
          <List>
          {filteredTeamsList.map((team, index) => (
            <div key={index}>
              <Accordion
               sx={{ backgroundColor: "transparent",
                
                }}>
                <AccordionSummary
                
                  // expandIcon={<PlusBUtton />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  onClick={(event) => event.stopPropagation()}
                >
                  <FormControlLabel

                    label={
                      <Typography
                        sx={{
                          ...typography.subtitle.regularColorless,
                          color: "white",
                        }}
                      >
                        {team.teamName}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={selectedTeamId === team.teamId}
                        onChange={(event) =>
                          handleChange1(event, team.teamId, team.teamName)
                        }
                      />
                    }
                  />
                </AccordionSummary>
                {/* <AccordionDetails>{children}</AccordionDetails> */}
              </Accordion>
            </div>
          ))}
          </List>
        )}
        {!clickWithOrg && selectedTeamId && (
        <List>
          {filteredTeamsList.map((team, index) => (
            <div key={index}>
              <Accordion sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary
                  // expandIcon={<PlusBUtton />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  onClick={(event) => event.stopPropagation()}
                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          ...typography.subtitle.regularColorless,
                          color: "white",
                        }}
                      >
                        {team.teamName}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={selectedTeamId === team.teamId}
                        onChange={(event) =>
                          handleChange1(event, team.teamId, team.teamName)
                        }
                      />
                    }
                  />
                </AccordionSummary>
                {/* <AccordionDetails>{children}</AccordionDetails> */}
              </Accordion>
            </div>
          ))}
        </List>
        )}
      </Scrollbar>
    </Box>
  );

  React.useEffect(() => {
    // Open the drawer directly when the component mounts
    setState((prev) => ({ ...prev, left: true }));
  }, []);

  useEffect(() => {
    if (teamsList) {
      setIsLoading(false);
    }
  }, [teamsList]);

  //loader animation will run until response fetched from backend
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  // if(noTeam) {
  //    return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "100vh", // Adjust this based on your layout
  //       }}
  //     >
  //       <SldButtons.BlackButtonCap variant="contained">
  //         <Typography textAlign="center">
  //           User <br />
  //           Not Yet Assigned to Team
  //         </Typography>
  //       </SldButtons.BlackButtonCap>
  //     </div>
  //   )
  // }

  return (
    <Drawer
      anchor="left"
      open={state["left"]}
      onClose={() => {
        setDrawerOpen(false);
        toggleDrawer("left", false);
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0)!important", // Set the background color of the backdrop to transparent
        },
       '& .MuiPaper-root.MuiPaper-elevation': {
          left: {
            lg: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,
            md: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,
            sm: 0,
            xs: 0,
          },
          top:{lg:'0',md:'0', sm:'155px', xs:'155px'},
          zIndex: -1, overflow: 'hidden',
          height:{lg:'100%', md:'100%', sm:'70%', xs:'calc(100vh - 200px)'}
        },

        '& .MuiPaper-root.MuiPaper-elevation .MuiPaper-root.MuiPaper-elevation': { left: 0, zIndex: 1,top:{lg:' ',md:'', sm:'0', xs:'0'},
        height:'100%' },
        "& .MuiButtonBase-root": {
          "& rect": { fill: "#fff", stroke: "#fff;" },
        },
        "& .MuiAccordionDetails-root .MuiBox-root": { marginLeft: 0 },
        "& .MuiAccordionSummary-root": {
          borderBottom: "1px solid rgba(255,255,255,.3)",
          borderTop: "1px solid rgba(255,255,255,.3)",
        },
        "& .MuiAccordionSummary-content": { margin: "2px 0!important" },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "25px",
          margin: "2px 0",
        },
        "& .MuiButtonBase-root.MuiCheckbox-root": { padding: "4px" },
        "& .MuiFormControlLabel-root .MuiTypography-root": { paddingLeft: 0 },
        '& .MuiButtonBase-root.Mui-checked':{color:'#fff!important'}

      }}
    >
      {list("left")}

    </Drawer>
  );
}
