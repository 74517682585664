/**
 * @Author: Anandp
 * @Date:   2024-07-25 06:04:10
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-04-09 10:25:42
 */
import React, { createContext, useContext, useEffect, useState } from "react";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
import { useUser } from "src/hooks/use-user";
import CryptoJS from 'crypto-js';
const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const user = useUser();
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [isLoadingTeam, setIsLoading] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  const [selectedTeamName, setSelectedTeamName] = useState(null);
  const [clickWithOrg, setClickWithOrg] = useState(false);
  const [teamChangeState, setTeamChangeState] = useState(false);
  const [clickWithPage, setClickWithPage] = useState(false);
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);

  const setTeamName = (teamName) => {
    setSelectedTeamName(teamName);
  };
  const setTeamId = (teamId) => {
    sessionStorage.setItem("teamSelectedTeam",teamId)
    setSelectedTeamId(teamId);
  };
  const setIsLoadingTeam = (bool) => {
    setIsLoading(bool);
  };
  const setToClickWithOrg = (bool) => {
    setClickWithOrg(bool);
  };
  const seteamChange = (bool) => {
    setTeamChangeState(bool);
  };
  const setToClickWithPage = (bool) => {
    setClickWithPage(bool);
  };
  const updatePermission = async (id) => {
    setIsLoading(true);
    if (typeof id === 'number' && id > 0) {
      let teamResponse = await mtpDashboardResultsApi.getTeamPermissions(
        accessToken,
        id,
        user.id
      );
      const permissions = teamResponse.data;
      if (teamChangeState) {
        seteamChange(false);
        if (permissions.includes(6)) {
          window.location.href = '/teamDiversity';
        } else if (permissions.includes(7)) {
          window.location.href = '/clickWith?clickWithType=team';
        } else if (permissions.includes(18) && user.tenetId !== 0) {
          window.location.href = '/clickWith?clickWithType=org';
        } else if (permissions.includes(8)) {
          window.location.href = '/teamCompatability';
        } else if (permissions.includes(5)) {
          window.location.href = '/teamMojo';
        } else if (surveyPerms && surveyPerms.includes(5)) {
          window.location.href = '/leadershipBrand';
        } else if (permissions.includes(9)) {
          window.location.href = '/teamDevelopment';
        } else {
          window.location.href = '/teamDiversity';
        }
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }

    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (selectedTeamId) {
      updatePermission(selectedTeamId)
    }
    // eslint-disable-next-line
  }, [selectedTeamId])

  return (
    <TeamContext.Provider
      value={{
        selectedTeamId,
        setTeamId,
        setTeamName,
        selectedTeamName,
        setToClickWithOrg,
        teamChangeState,
        seteamChange,
        isLoadingTeam,
        setIsLoadingTeam,
        clickWithOrg,
        setToClickWithPage,
        clickWithPage,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  return useContext(TeamContext);
};
